<template>
  <div class="tab-pane" :id="`tabs-1.${index}`" role="tabpanel">
    <div class="lesiurpackages_box organized tours" v-if="applyFilter.length>0">
      <div class="row" v-for="(rowItem, rowIndex) in applyFilter" :key="rowIndex">
        <div class="col-lg-8" v-if="rowIndex % 2 === 0">
          <div class="row">
            <LesiurtabItem :items="rowItem[0]" :isBig="!!rowItem[1]" />
          </div>
        </div>

        <div :class="rowItem.length < 3 ? 'col-lg-8' : 'col-lg-4'" v-else>
          <div class="row">
            <LesiurtabItem :items="rowItem[0]" />
            <LesiurtabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
          </div>
        </div>

        <div class="col-lg-4" v-if="rowIndex % 2 === 0">
          <div class="row">
            <LesiurtabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
            <LesiurtabItem :items="rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>

        <div class="col-lg-8" v-else>
          <div class="row">
            <LesiurtabItem :items="rowItem[2]" :isBig="!!rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>

      </div>
    </div>
    <div class="d-flex loadMore">
      <button style="height: 40px" @click="loadMore" v-if="this.dealListItem.list.length > this.setNum">{{ $t('home.show-more') }}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tabContent',
  components: {
    LesiurtabItem: () => import('@/components/home/LesiurtabItem'),
  },
  props: {
    dealListItem: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    filterConditions: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rowGroup: [],
      setNum: 10,
    };
  },
  mounted() {
    this.dealListItem.list.forEach((element, inx) => {
      if (inx % 3 === 0) this.rowGroup.push([]);
      this.rowGroup[Math.floor(inx / 3)].push(element);
    });
  },
  computed: {
    applyFilter() {
      const { filterConditions } = this;
      const jsonFilterCondition = JSON.parse(filterConditions);

      let result = [];
      if (this.dealListItem.list.length > this.setNum) {
        result = this.dealListItem.list.slice(0, this.setNum);
      } else {
        result = this.dealListItem.list;
      }

      if (jsonFilterCondition.length !== 0) {
        if (jsonFilterCondition.price) {
          const resultPrice = result.filter((item) => Number(item[1][0].totalAfterDiscount) >= jsonFilterCondition.price[0]
            && Number(item[1][0].totalAfterDiscount) <= jsonFilterCondition.price[1]);
          result = [...resultPrice];
        }

        const resultHotel = result.filter((item) => {
          const cond1 = (jsonFilterCondition['hotel-name'] && jsonFilterCondition['hotel-name'].length) ? jsonFilterCondition['hotel-name'].indexOf(item[1][0].hotelName) > -1 : true;
          const cond2 = (jsonFilterCondition['hotel-basis'] && jsonFilterCondition['hotel-basis'].length) ? jsonFilterCondition['hotel-basis'].indexOf(item[1][0].basisName) > -1 : true;
          const cond3 = (jsonFilterCondition['hotel-dest'] && jsonFilterCondition['hotel-dest'].length) ? jsonFilterCondition['hotel-dest'].indexOf(item[1][0].destCode) > -1 : true;
          return cond1 && cond2 && cond3;
        });
        result = [...resultHotel];

        let sorted = [];
        if (jsonFilterCondition.sort) {
          sorted = result.slice().sort((firstItem, secondItem) => {
            if (firstItem[1][0].totalAfterDiscount < secondItem[1][0].totalAfterDiscount) return 1;
            if (firstItem[1][0].totalAfterDiscount > secondItem[1][0].totalAfterDiscount) return -1;
            return 0;
          });
          result = [...sorted];
        } else {
          sorted = result.slice().sort((firstItem, secondItem) => {
            if (firstItem[1][0].totalAfterDiscount < secondItem[1][0].totalAfterDiscount) return -1;
            if (firstItem[1][0].totalAfterDiscount > secondItem[1][0].totalAfterDiscount) return 1;
            return 0;
          });
          result = [...sorted];
        }
      }

      const rowGroup = [];
      result.forEach((element, inx) => {
        if (inx % 3 === 0) rowGroup.push([]);
        rowGroup[Math.floor(inx / 3)].push(element);
      });

      return rowGroup;
    },
  },
  methods: {
    loadMore() {
      this.setNum += 10;
    },
  },
};
</script>
<style scoped>
.loadMore {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.loadMore > button {
  padding: 10px 24px;
  border-radius: 50px;
  border: 1px solid rgb(0, 97, 171);
  border: 1px solid rgba(var(--theme-primary));
  cursor: pointer;
}
</style>
